<script>
export default {
  metaInfo() {
    return {
      title: 'Become a funder - About - Europe PMC',
    }
  },
}
</script>
<template>
  <div id="joining-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Become a Europe PMC funder</h1>
        <h2>Benefits for funders</h2>
        <p>
          As a funder, joining Europe PMC will enable your grantees to make
          their publications freely available, helping to increase the
          visibility of the research you fund, and supporting the implementation
          of your open access policies.
        </p>
        <p>
          The following presentation outlines the key benefits of participating
          in Europe PMC:
        </p>
        <p>
          <a href="/doc/Joining_Europe_PMC.pdf"
            >https://europepmc.org/doc/Joining_Europe_PMC.pdf</a
          >
        </p>
        <div>
          <a
            href="/doc/Joining_Europe_PMC.pdf"
            aria-label="Link to the Joining Europe PMC file"
          >
            <img
              src="@/assets/BecomeAFunder.jpg"
              class="med-img"
              alt="Become a funder"
            />
          </a>
        </div>

        <h2>Membership Criteria</h2>
        <p>
          Membership in the Europe PMC funder group is currently limited to
          organisations that are either:
        </p>
        <ul>
          <li>
            Public organisations whose legal mission is research funding or
          </li>
          <li>
            Not-for-profit organisations (charities, foundations, associations)
            that fund research as a part of their mission.
          </li>
        </ul>
        <p>
          In both cases the organisation must fund research in the life sciences
          and related disciplines as a key part of their portfolio.
        </p>
        <p>An eligible organisation that wishes to join Europe PMC must:</p>
        <ul>
          <li>
            Have a publicly accessible open access (OA) policy, which as a
            minimum, requires all research articles arising from its funding
            must be made OA within 6 months of publication.
          </li>
          <li>
            Be prepared to sign the
            <a href="/doc/Collaboration_agreement_2016-2021_Website.pdf"
              >Europe PMC Collaboration Agreement</a
            >
            and meet the agreed funding contributions.
          </li>
          <li>
            Be prepared to provide Europe PMC with a list of grantees – and
            update this on an agreed, regular basis.
          </li>
          <li>Make grant metadata openly available.</li>
        </ul>

        <h3>Other Organisations</h3>
        <p>
          Organisations that do not meet the membership criteria above (e.g. a
          for-profit pharmaceutical company with a compliant OA policy) should
          contact Hannah Hope,
          <a href="mailto:h.hope@wellcome.org">h.hope@wellcome.org</a>, to
          discuss their requirements.
        </p>

        <h2>Application Process</h2>
        <p>
          Organisations interested in joining Europe PMC should send an email to
          Hannah Hope,
          <a href="mailto:h.hope@wellcome.org">h.hope@wellcome.org</a>, setting
          out how they meet the above criteria.
        </p>
        <p>
          In accordance with the Europe PMC Collaboration Agreement (link) all
          new applications received will be reviewed by the Wellcome Trust to
          assess the new Member’s compatibility with the Europe PMC Funders’
          Group mission and make a recommendation to the Europe PMC Funders’
          Group if the new Member should be admitted.
        </p>
        <p>
          Europe PMC Funders’ Group Members shall have two (2) weeks to register
          any objection to the new Member.
        </p>
        <p>
          Formal membership will commence once the new applicant has signed the
          Collaboration Agreement.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#joining-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
  .med-img {
    width: $base-unit * 128;
  }
}
</style>
